@import '@core/scss/core.scss';
@import './assets/scss/styles';
@import '@core/scss/angular/libs/datatables.component.scss';
@import '@core/scss/angular/libs/tree-view.component.scss';
@import '@core/scss/angular/libs/select.component.scss';

ng-select.ng-invalid.ng-touched .ng-select-container {
    border-color: #dc3545;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px #fde6e8;
}

.id-route {
    text-decoration: underline !important;
    color: #FF7A45 !important;
}

.break-word {
    .datatable-body-cell-label {
        overflow-y: auto !important;
        max-height: 300px;
    }
    span {
        white-space: pre-wrap !important;
        
    }
}