@import '@circlon/angular-tree-component/css/angular-tree-component.css'; // vendor css
@import '@core/scss/base/bootstrap-extended/include'; // Bootstrap includes
@import '@core/scss/base/components/include'; // Components includes

// variables
$treeview-border-color: $gray-700;
$treeview-box-shadow: $gray-300;
$treeview-drag-bg-color: $gray-50;

$treeview-bg-color: $theme-dark-border-color;
$treeview-bg-hover: rgba($gray-50, 0.25);

// tree wrapper
.tree-wrapper {
  .tree-children {
    padding-left: 20px;
    overflow: hidden;
  }

  .node-drop-slot {
    display: block;
    height: 2px;
  }

  .node-drop-slot.is-dragging-over {
    background: $treeview-drag-bg-color;
    height: 20px;
    border: 2px dotted $primary;
  }

  .toggle-children-wrapper {
    padding: 0;
  }

  .toggle-children-placeholder {
    display: inline-block;
    height: 10px;
    width: 10px;
    position: relative;
    top: 1px;
    padding-right: 3px;
  }

  .node-content-wrapper {
    display: inline-block;
    padding: 2px 5px;
    border-radius: 2px;
    transition: background-color 0.15s, box-shadow 0.15s;

    &:hover {
      background: $gray-50;
    }
  }

  .node-content-wrapper-active {
    background: rgba($primary, 0.4) !important;
  }

  .node-content-wrapper-focused {
    background-color: rgba($primary, 0.2) !important;
  }

  .node-content-wrapper.is-dragging-over-disabled {
    opacity: 0.5;
  }

  .empty-tree-drop-slot {
    .node-drop-slot {
      height: 20px;
      min-width: 100px;
    }
  }

  .angular-tree-component {
    width: 100%;
    position: relative;
    display: inline-block;
    cursor: pointer;
    -webkit-touch-callout: none;
  }

  div.angular-tree-component {
    div.tree-children {
      &::before {
        content: '';
        position: absolute;
        border-left: 1px dotted $treeview-border-color;
        height: 100%;
        top: -10px;
        left: 6px;
        left: 5px;
      }

      position: relative;
      padding-left: 0;
      margin-left: 16px;
    }

    &::before {
      content: '';
      position: absolute;
      border-left: 1px dotted $treeview-border-color;
      height: 100%;
      top: -10px;
      left: 6px;
    }

    padding: 0;
    margin: 0;

    tree-node {
      > div {
        > .node-wrapper {
          margin-left: 24px;

          > .node-content-wrapper {
            margin-left: 4px;
          }
        }

        &::before {
          content: '';
          position: absolute;
          border-bottom: 1px dotted $treeview-border-color;
          width: 7px;
          margin-top: 12px;
          left: 6px;
        }

        .toggle-children-wrapper {
          width: 13px;
          height: 13px;
          border: 1px solid $treeview-border-color;
          position: relative;
          margin-top: 5px;
          margin-left: 14px;
          display: inline-block;
          background-color: $white;
          z-index: 1;

          &::before {
            content: '';
            display: inline-block;
            width: 7px;
            border-top: 1px solid $treeview-border-color;
            position: absolute;
            top: 5px;
            left: 2px;
          }

          .toggle-children {
            display: none;
          }
        }

        .toggle-children-wrapper.toggle-children-wrapper-collapsed {
          &::after {
            content: '';
            display: inline-block;
            height: 7px;
            border-left: 1px solid $treeview-border-color;
            position: absolute;
            top: 2px;
            left: 5px;
          }
        }

        .node-content-wrapper {
          margin: 0 0 0 4px;
          padding: 0 4px 0 4px;
          box-shadow: none;
        }
      }

      > div.tree-node-leaf {
        .node-wrapper {
          margin-left: 0;
          position: relative;
        }
      }
    }

    > tree-node {
      > div {
        &::before {
          left: 14px;
        }
      }
    }
  }

  .node-content-wrapper-focused {
    box-shadow: inset 0 0 1px $treeview-box-shadow;
  }

  .node-content-wrapper {
    &:hover {
      box-shadow: inset 0 0 1px $treeview-box-shadow;
    }
  }

  tree-root {
    .angular-tree-component-rtl {
      direction: rtl;

      .toggle-children-wrapper-collapsed {
        .toggle-children {
          transform: rotate(180deg) !important;
        }
      }

      .tree-children {
        padding-right: 20px;
        padding-left: 0;
      }
    }
  }

  li {
    display: inline;
    list-style-type: none;
    padding-right: 20px;
    float: left;
  }

  &.tree-checkbox {
    .toggle-children-wrapper {
      margin-left: 7px !important;
    }

    tree-node-checkbox {
      padding: 2px 0px 0px 16px;
    }

    .tree-node-loading {
      margin: 10px !important;
      font-family: 'feather' !important;

      &:before {
        content: '\e889';
        margin-right: 2px;
      }
    }

    input[type='checkbox']:before {
      content: '';
      display: inline-block;
      height: 13px;
      width: 13px;
      border-radius: 2px;
      margin: 0px 0px 2px 0px;
    }

    input[type='checkbox']:indeterminate:before {
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E");
      background-color: $primary;
      background-size: 0.5rem;
      background-repeat: no-repeat;
      background-position: center;
    }

    input[type='checkbox']:checked:before {
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9.5 7.5'%3E%3Cpolyline points='0.75 4.35 4.18 6.75 8.75 0.75' style='fill:none;stroke:%23fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px'/%3E%3C/svg%3E");
      background-color: $primary;
      background-size: 0.6rem;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
}

// dark layout style
.dark-layout {
  .tree-wrapper {
    .node-content-wrapper {
      &:hover {
        background: $treeview-bg-hover;
      }
    }
    input[type='checkbox']:before {
      background-color: $treeview-bg-color;
    }

    .toggle-children-wrapper {
      background-color: $treeview-bg-color !important;

      &::before {
        border-top: 1px solid $white !important;
      }
      &::after {
        border-left: 1px solid $white !important;
      }
    }
  }
}
